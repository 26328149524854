<template>
	<Error :errors="errors"/>

    <Button label="Dashboard GT" class="p-button-rounded my-3" />

	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
			<div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>User</label>
                    <MultiSelect id="userweb_id" dataKey="userweb_id" v-model="userweb_ids" :loading="loadingDropdownASPM"
                        :options="dataDropdownASPM"
                        optionLabel="label" placeholder="Pilih User" :filter="true" :showToggleAll="false"
                        :showClear="true" @filter="searchDropdownASPM($event, 'filter')"/>
                </div>
			</div>
		</div>
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-4">
			<UserAktif :filter="filters"/>
        </div>
		<div class="col-12 md:col-6 lg:col-4">
			<OutletMitra :filter="filters"/>
        </div>
		<div class="col-12 md:col-6 lg:col-4">
			<OutletSpreading :filter="filters"/>
        </div>
	</div>

	<div class="grid mt-2">
		<div class="col-12 md:col-12 lg:col-12">
            <h4>Productivity Monitoring</h4>
			<ProductivityMonitoringChart :filter="filters"/>
        </div>
	</div>

	<hr>

	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Period</label>
                    <Dropdown dataKey="month" v-model="filters2.period" :loading="loadingDropdownPeriod"
                        :options="dataDropdownPeriod"
                        optionLabel="label" optionValue="month" placeholder="Pilih Bulan"
                        :showClear="false"
                     />
                </div>
            </div>
		</div>
	</div>

    <Fieldset legend="RTM MITRA">
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h4>Visit</h4>
                <VisitChart :filter="filters" :filter2="filters2"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h4>Display</h4>
                <DisplayChart :filter="filters" :filter2="filters2"/>
            </div>
        </div>
    </Fieldset>

    <Fieldset legend="RTM SPREADING" class="mt-3">
		<div class="p-fluid">
			<div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Tipe Outlet</label>
                    <MultiSelect id="otype_id" dataKey="otype_id" v-model="otype_ids" :loading="loadingDropdownOutletType"
                        :options="dataDropdownOutletType"
                        optionLabel="label" placeholder="Pilih Tipe Outlet" :filter="true" :showToggleAll="false"
                        :showClear="true" @filter="searchDropdownOutletType($event, 'filter')"/>
                </div>
			</div>
		</div>
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter2"/>
        <div class="grid mt-2 mb-2">
            <div class="col-12 md:col-6 lg:col-4">
                <EC :filter="filters" :filter2="filters2" :filter3="filters3"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <NonEC :filter="filters" :filter2="filters2" :filter3="filters3"/>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <SalesValue :filter="filters" :filter2="filters2" :filter3="filters3"/>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h4>Visit</h4>
                <VisitSpreadingChart :filter="filters" :filter2="filters2" :filter3="filters3"/>
            </div>
            <div class="col-12 md:col-6 lg:col-6">
                <h4>Top 10 Products</h4>
                <Top10ProductsChart :filter="filters" :filter2="filters2" :filter3="filters3"/>
            </div>
        </div>
    </Fieldset>

	<div class="grid mt-2 mb-2">
		<div class="col-12">
			<div class="card ">
				<h4>MAPS</h4>
				<Map :filter="filters" :filter2="filters2"/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAktif from '../components/dashboard/gt/UserAktif.vue';
import OutletMitra from '../components/dashboard/gt/OutletMitra.vue';
import OutletSpreading from '../components/dashboard/gt/OutletSpreading.vue';
import VisitSpreadingChart from '../components/dashboard/gt/VisitSpreadingChart.vue';
import VisitChart from '../components/dashboard/gt/VisitChart.vue';
import DisplayChart from '../components/dashboard/gt/DisplayChart.vue';
import EC from '../components/dashboard/gt/EC.vue';
import NonEC from '../components/dashboard/gt/NonEC.vue';
import SalesValue from '../components/dashboard/gt/SalesValue.vue';
import Top10ProductsChart from '../components/dashboard/gt/Top10ProductsChart.vue';
import ProductivityMonitoringChart from '../components/dashboard/gt/ProductivityMonitoringChart.vue';
import Map from '../components/dashboard/gt/MapNew.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		'UserAktif': UserAktif,
		'OutletMitra': OutletMitra,
		'OutletSpreading': OutletSpreading,
		'VisitSpreadingChart': VisitSpreadingChart,
		'VisitChart': VisitChart,
		'DisplayChart': DisplayChart,
		'EC': EC,
		'NonEC': NonEC,
		'SalesValue': SalesValue,
		'Top10ProductsChart': Top10ProductsChart,
		'ProductivityMonitoringChart': ProductivityMonitoringChart,
		'Map': Map,
		'Error': Error,
	},
	computed:{
        ...mapGetters(['errors']),
    },
	data() {
        const today = new Date();
        const currentMonth = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0');
        
		return {		
            // loading
            loadingDropdownASPM: false,
            loadingDropdownOutletType: false,
            loadingDropdownPeriod: false,

            // dataDropdown
            dataDropdownASPM: null,
            dataDropdownOutletType: null,
            dataDropdownPeriod: null,

            // filter
            filters: {
                userweb_id: null,
            },
            userweb_ids: null,
            // filter 2
            filters2: {
                period: currentMonth,
            },
            // filter 3
            filters3: {
                otype_id: null,
            },
            otype_ids: null,
		}
	},    
	mounted() {
        this.searchDropdownASPM('');
        this.searchDropdownOutletType('');
        this.searchDropdownPeriod();
    },
    watch: {
        userweb_ids() {
            this.filters.userweb_id = this.userweb_ids ? this.convertMultiSelectUser(this.userweb_ids) : null;
        },
        otype_ids() {
            this.filters3.otype_id = this.otype_ids ? this.convertMultiSelectOutletType(this.otype_ids) : null;
        },
    },
	methods: {
        convertMultiSelectUser(data) {
            if(data){
                return data.map((item) => {
                    return item.userweb_id;
                });
            }
        },
        convertMultiSelectOutletType(data) {
            if(data){
                return data.map((item) => {
                    return item.otype_id;
                });
            }
        },
        clearFilter(){
            this.filters.userweb_id = null;
            this.userweb_ids = null;
        },
        clearFilter2(){
            this.filters3.otype_id = null;
            this.otype_ids = null;
        },
        //DROPDOWN
        searchDropdownASPM(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dsalesman.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownASPM = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/aspm',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownASPM = res.data.data;
                            this.loadingDropdownASPM = false;

                            //Multiple Selected
                            if(this.userweb_ids){
                                this.dataDropdownASPM.push(...this.userweb_ids);

                                const uniqueids= [];
                                this.dataDropdownASPM = this.dataDropdownASPM.filter((c) => {
                                    const isDup = uniqueids.includes(c.userweb_id);
                                    if(!isDup){
                                        uniqueids.push(c.userweb_id);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownASPM = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownOutletType(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.doutlettype.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownOutletType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-outlet-type',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownOutletType = res.data.data;
                            this.loadingDropdownOutletType = false;

                            //Multiple Selected
                            if(this.otype_ids){
                                this.dataDropdownOutletType.push(...this.otype_ids);

                                const uniqueids= [];
                                this.dataDropdownOutletType = this.dataDropdownOutletType.filter((c) => {
                                    const isDup = uniqueids.includes(c.otype_id);
                                    if(!isDup){
                                        uniqueids.push(c.otype_id);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownOutletType = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownPeriod() {
            setTimeout(() => {
                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/month',
                })
                .then(res => {
                    this.dataDropdownPeriod = res.data.data;
                    this.loadingDropdownPeriod = false;
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
	}
}
</script>
<style>
	.banner {
		width: 100%;
		/* height: 200px; */
		background-color: #f0f0f0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.banner img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
</style>