<template>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>
    <div>
        <DashboardGT v-if="user?.level_id == 1 || user?.level_id == 2 || user?.level_id == 3" />
        <DashboardMTI v-if="user?.level_id == 5 || user?.level_id == 6 || user?.level_id == 7"  />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DashboardGT from './DashboardGT.vue';
import DashboardMTI from './DashboardMTI.vue';


export default {
	components: {
		'DashboardGT': DashboardGT,
		'DashboardMTI': DashboardMTI,
	},
	computed: {
		...mapGetters("auth", ["user"]),
	},
	data() {
        return {
            appsName: process.env.VUE_APP_APPS_NAME,
        }
    },
	mounted() {
		if (localStorage.getItem("authToken")) {
			this.getUserData();
		}
	},
    methods: {
		...mapActions("auth", ["getUserData"]),
    }
}
</script>